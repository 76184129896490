<template>
  <div class="cssloader">
    <div class="sh1"></div>
    <div class="sh2"></div>
    <h4 class="lt">نشـــوف</h4>
</div>
</template>

<script>
export default {
  name: 'Loding',
  // mounted() {
  //   let externalScript = document.createElement('script')
  //   externalScript.setAttribute('src', 'assets/js/loader.js')
  //   document.head.appendChild(externalScript)
  // },
};
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:100');
body {
  background: #e6feff;
}

/** page **/
.cssloader {
  padding-top: calc(45vh - 25px);
  padding-bottom: calc(45vh - 40%);
}
@media (min-width:768px){
.cssloader{
padding-bottom: calc(45vh - 35%);
}
}
/** loader **/
.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #257d7f  transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #1dd6da  transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }  
  
  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #1e6667;
  font-family: "Zain" !important;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  
}
</style>


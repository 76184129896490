<template>
          <!-- footer -->
    <center>
        <footer class="footer">
            <div class="container">


                <div class="row">
                    <div class="col-12">
                        <div class="footer__content">
                            <!--                            <a href="index.php" class="header__logo" style="margin-left: 5px">
                                                            <img src="img/logoSvg.svg" alt="">
                                                        </a>-->
                                                    <!--<p class="footer__tagline">Movies & TV Shows, Online cinema,<br> Movie database HTML Template.</p>-->
                            <div class="footer__social">
                                <img style="width: 100px;" src="assets/img/logoSvg.svg" alt="">
                                </div>
                            <!--                            <div class="footer__links">
                                                            <a href="privacy.html">Privacy policy</a>
                                                            <a href="privacy.html">Terms and conditions</a>
                                                        </div>-->
                                                        <span>
                            
                             <small class="footer__copyright">Powered by ELGEDEES </small> 
                             <small class="footer__copyright">Designed by BYTE © 2021</small> 
                            </span>
                        </div>
                    </div>
                </div>
                </div>
        </footer>
    </center>
    <!-- end footer -->
</template>

<script>
export default {
   name: 'Footer'
}
</script>

<style>

</style>